@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


* {
    font-family: 'Montserrat', sans-serif;
}

.tjecko-logo {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 700;
}

body {
    /* Supported by: Chrome 36, Chrome Android 36, Edge, Firefox 52, Opera 23, Safari 13, Safari iOS 9.3 */
    /*noinspection CssBrowserCompatibilityForProperties*/
    touch-action: none;
}

.swiper {
    width: 300px;
    height: 320px;
}


.swiper-slide-next.card-2 {
    transform: translate3d(calc(6% - 300px), 20px, -50px) rotateZ(0deg) scale(1) !important;
}

.swiper-slide-next.card-3 {
    transform: translate3d(calc(7% - 600px), 25px, -100px) rotateZ(0deg) scale(1) !important;
}

.swiper-slide.card-3:not(.swiper-slide-active):not(.swiper-slide-next) {
    transform: translate3d(calc(14% - 600px), 50px, -150px) rotateZ(0deg) scale(1) !important;
}

.swiper-slide-prev.card-1 {
    transform: translate3d(calc(-7% + 0px), 25px, -100px) rotateZ(0deg) scale(1) !important;
}

.swiper-slide-prev.card-2 {
    transform: translate3d(calc(-5.5% - 300px), 20px, -50px) rotateZ(0deg) scale(1) !important;
}

.swiper-slide.card-1:not(.swiper-slide-prev):not(.swiper-slide-active) {
    transform: translate3d(calc(-13.5% + 0px), 50px, -150px) rotateZ(0deg) scale(1) !important;
}

.editor-textBold {
    font-weight: bold;
}

.editor-textItalic {
    font-style: italic;
}

.editor-textUnderline {
    text-decoration-line: underline;
}

.editor-textUnderlineStrikethrough {
    text-decoration-line: line-through;
}

.editor-link {
    color: blue
}