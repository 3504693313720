$paddingX: 0rem;
$offsetLeft: 0.25rem;


.tjecko-chart {
  mask-image: linear-gradient(// offset bottom
                  to bottom,
                  transparent 0%,
                  transparent calc(100% - 2rem),
                  white calc(100% - 0.75rem),
                  white 100%
  ),
  linear-gradient(// Fade X
                  to right,
                  transparent 0%,
                  transparent 2rem,
                  white calc($paddingX + $offsetLeft),
                  white calc(100% - $paddingX),
                  transparent 100%
  )
}