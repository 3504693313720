.loading-dots {
    font-weight: inherit;
    font-size: inherit;
    display: inline-block;
    font-family: monospace;
    clip-path: inset(0 3ch 0 0);
    animation: loading-dot-frames 1.5s steps(4) infinite;
}

@keyframes loading-dot-frames {
    to {
        clip-path: inset(0 -1ch 0 0)
    }
}